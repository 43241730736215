<template>
  <div class="UserCreation" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Ajouter un nouveau collaborateur</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Une erreur s'est produite">
              <div class="text-center">
                <p>Une erreur s'est produite,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="CollaborateurCreatedModal" id="CollaborateurCreatedModal" hide-footer title="Collaborateur créé">
              <div class="text-center">
                <p><strong>Votre collaborateur a bien été créé ! </strong></p>

                <b-button class="mt-3" block pull variant="outline-dark" @click="$router.push('/users')">
                  Retour à la liste des collaborateurs
                </b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#F9BF3B">
            </loading>

            <div class="text-right">
              <small><em> <span style="color: red;">*</span>Champs obligatoires</em></small>
            </div>
            <b-form v-on:submit.prevent="createCollaborateur()">
              <b-form-group id="create-collaborateur-username-group" label="Email" label-for="create-collaborateur-username-input" class="mandatory-input">
                <b-form-input
                  v-model="username" type="email"
                  maxlength="149"
                  placeholder="Email"
                  id="create-collaborateur-username-input"
                  @input="$v.username.$touch()"
                  :state="$v.username.$dirty ? !$v.username.$error : null">
                </b-form-input>
                <small class="text-info"> Il s'agira de son identifiant pour se connecter à son compte</small><br>
                <div class="form-error" v-if="!$v.username.email">Veuillez renseigner un format d'email valide.</div>
              </b-form-group>

              <div class="row">
                <div class="col-md-4">
                  <b-form-group id="create-collaborateur-sex-group"
                    label="Genre"
                    label-for="create-collaborateur-sex-input"
                    class="mandatory-input">
                    <b-form-select
                      v-model="sex"
                      id="create-collaborateur-sex-input"
                      @input="$v.sex.$touch()"
                      :state="$v.sex.$dirty ? !$v.sex.$error : null"
                      :options='genderOptions'>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-4">
                  <b-form-group id="create-collaborateur-first_name-group" label="Prénom" label-for="create-collaborateur-first_name-input" class="mandatory-input">
                    <b-form-input
                      v-model="firstName" type="text"
                      maxlength="29"
                      placeholder="Prénom"
                      id="create-collaborateur-first_name-input"
                      @input="$v.firstName.$touch()"
                      :state="$v.firstName.$dirty ? !$v.firstName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.firstName.$error">Votre prénom doit comporter entre 2 et 30 caractères</div>
                  </b-form-group>
                </div>
                <div class="col-md-4">
                  <b-form-group id="create-collaborateur-last_name-group" label="Nom" label-for="create-collaborateur-last_name-input" class="mandatory-input">
                    <b-form-input
                      v-model="lastName" type="text"
                      maxlength="149"
                      placeholder="Nom"
                      id="create-collaborateur-last_name-input"
                      @input="$v.lastName.$touch()"
                      :state="$v.lastName.$dirty ? !$v.lastName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.lastName.$error">Votre nom doit comporter entre 2 et 150 caractères</div>
                  </b-form-group>
                </div>
              </div>

              <b-form-group id="create-collaborateur-password-group" label="Mot de passe" label-for="create-collaborateur-password-input" class="mandatory-input">
                  <b-form-input
                    v-model="password" type="password"
                    maxlength="249"
                    placeholder="Mot de passe"
                    id="create-collaborateur-password-input"
                    @input="$v.password.$touch()"
                    :state="$v.password.$dirty ? !$v.password.$error : null">
                  </b-form-input>
                  <small class="text-info"> Votre collaborateur pourra le changer lors de sa première connexion</small><br>
                  <div class="form-error" v-if="!$v.password.password_validation">Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.</div>
                </b-form-group>

                <b-form-group id="create-collaborateur-confirm-password-group" label="Confirmez le mot de passe"
                  label-for="create-collaborateur-confirm-password-input" class="mandatory-input">
                  <b-form-input
                    v-model="passwordConfirm" type="password"
                    maxlength="249"
                    placeholder="Mot de passe"
                    id="create-collaborateur-confirm-password-input"
                    @input="$v.passwordConfirm.$touch()"
                    :state="$v.passwordConfirm.$dirty ? !$v.passwordConfirm.$error : null">
                  </b-form-input>
                </b-form-group>

              <b-button
                pill block type="submit" variant="outline-dark" class="mt-2"
                :disabled="isInvalidCollaborateur">
                  Créer un collaborateur
              </b-button>
            </b-form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, sameAs} from 'vuelidate/lib/validators'
import { password_validation } from '@/validators/validators'
import { APIUser } from '@/api/APIUser'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUser = new APIUser()

export default {
  name: 'UserCreation',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      isProfileLoading: false,

      sex: 'H',
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      passwordConfirm: '',

      genderOptions: [
        { text: 'Un homme', value: 'H' },
        { text: 'Une femme', value:  'F' },
      ],
      createCollaborateurInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidCollaborateur () {

      if (this.$v.firstName.$invalid || this.$v.lastName.$invalid || this.$v.username.$invalid || this.$v.password.$invalid || this.$v.passwordConfirm.$invalid) {
        return true
      }
      return false
    },
    isLoading () {
      if (this.createCollaborateurInProcess) {
        return true
      }
      return false
    },
  },
  validations: {
    sex: {
      required
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    username: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150),
      email
    },
    password: {
      maxLength: maxLength(250),
      required,
      password_validation
    },
    passwordConfirm: {
      maxLength: maxLength(250),
      required,
      sameAsPassword: sameAs('password'),
    },
  },

  methods: {
    createCollaborateur () {
      this.createCollaborateurInProcess = true
      apiUser.createCollaborateur(
        this.token, this.username, this.sex, this.firstName, this.lastName, this.password, this.passwordConfirm)
      .then(() => {
        this.$refs.CollaborateurCreatedModal.show()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('UserCreation createCollaborateur API Error : ' + String(error))
      })
      .finally(() => {
        this.createCollaborateurInProcess = false
      })

    },
  }
}
</script>
